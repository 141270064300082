
import { defineComponent, onMounted, watch, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "undewriter-policies-list",
  components: {},
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
      isEmptyArray,
    } = ResusableFunctions();

    const policies = ref([
      {
        client: "",
        reference: "",
        policyNumber: "",
        type: "",
        premium: 0,
        hasPolicyDocument: "",
        businessTypeShortCode: "",
        items: [],
        startDate: "",
        publicId: "",
      },
    ]);

    const getPolicies = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.UNDERWRITERS_POLICIES_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            policies.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    watch(
      () => policies.value,
      (newPolicies: any) => {
        if (!isEmptyArray(newPolicies)) {
          displayDatatable(["basicExample"]);
        }
      }
    );

    onMounted(() => {
      setCurrentPageTitle("Policy Listing");
      getPolicies();
    });

    return {
      useReusableNavigation,
      reinitialization,
      policies,
    };
  },
  created() {
    //Set page title
    document.title = "Policy Listing | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    gotoUrl(event) {
      this.$router.push(event);
    },
    hasCompletedCertification(vehicles) {
      const allEqual = (arr) =>
        arr.every((value) => {
          return value ? true : false;
        });

      // Array to be checked
      const arrayValues = [] as any;

      if (Array.isArray(vehicles)) {
        vehicles.every((item) => {
          arrayValues.push(item.isApproved);
        });
      }

      // Compare the values in the one-dimensional array
      return allEqual(arrayValues);
    },
  },
});
